import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/article'
import MainLayout from '@cmp/layout/MainLayout/MainLayout'
import sliceMapping from '@src/data/prismic-slice-mapping/article-slices'
import { GlobalDataProvider } from '@src/data/global-data'

const Slices = makeSlices(sliceMapping)

interface Props {
  data: any
}

const Article = ({ data: staticData }: Props) => {
  const data = usePreview(staticData)

  return (
    <GlobalDataProvider language='en' altUrls={[]}>
      <MainLayout>
        <article className='page-wrap'>
          <Slices body={data.prismicArticle.data.body} />
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Article

export const query = graphql`
  query genericArticleQuery($prismicId: ID!) {
    prismicArticle(prismicId: { eq: $prismicId }) {
      ...prismicArticleFragmentFull
    }
  }
`
