import {
  SEO,
  SEOProps,
  HeroMedia,
  ThreeImages,
  ThreeImagesProps,
  HtmlSerializer,
  HeroMediaType
} from '@islandsstofa/react'

import Img from 'gatsby-image'
import React from 'react'

export default {
  PrismicArticleBodySeo: {
    component: SEO,
    dataToProps: (data: any): SEOProps => ({
      title: data.primary.override_title,
      description: data.primary.override_description,
      image: data.primary.override_image,
      keywords: data.primary.override_keywords
    })
  },

  PrismicArticleBodyRichText: {
    component: HtmlSerializer,
    dataToProps(data: any) {
      return {
        raw: data.primary.rich_text.raw
      }
    }
  },

  PrismicArticleBodyHeroMedia: {
    component: HeroMedia,
    dataToProps(data: any): HeroMediaType {
      const {
        primary: {
          caption,
          single_image: singleImage,
          video,
          firstonpage: firstOnPage
        },
        items: gallery
      } = data
      return {
        caption,
        singleImage,
        video,
        gallery,
        firstOnPage,
        onRenderImageComponent: () => (
          <Img
            className='image-rounded'
            fluid={singleImage.fluid}
            alt={singleImage.alt}
          />
        )
      }
    }
  },

  PrismicArticleBodyThreeImages: {
    component: ThreeImages,
    dataToProps(data: any): ThreeImagesProps {
      const images = [
        data.primary.first_image,
        data.primary.second_image,
        data.primary.third_image
      ]
      return {
        images: images.filter(image => image.url)
      }
    }
  },
}
